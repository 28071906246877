import SpaceService from '~/services/SpaceService'
import ProviderService from '~/services/ProviderService'

export function useUrlHelpers() {
  const { normalizeStringForUrl } = useStringHelpers()

  const getSpaceUrl = (space: any): string => {
    let state = ''
    let city = ''
    let neighborhood = ''
    if (space.address) {
      state = normalizeStringForUrl(space.address.state)
      city = normalizeStringForUrl(space.address.city)
      neighborhood = normalizeStringForUrl(
        space.address.neighborhood ?? space.neighborhood
      )
    } else {
      state = normalizeStringForUrl(space.state)
      city = normalizeStringForUrl(space.city)
      neighborhood = normalizeStringForUrl(space.neighborhood)
    }
    const spaceName = normalizeStringForUrl(space.name)
    return `/espacos/${state}/${city}/${neighborhood}/${spaceName}--${space.id}`
  }

  const getSpaceUrlBySpaceId = async (spaceId: string): Promise<string> => {
    const space = await SpaceService.getSpaceById(spaceId)
    return getSpaceUrl(space)
  }

  const getProviderUrl = (provider: any): string => {
    const state = normalizeStringForUrl(provider.providerAddress.state)
    const city = normalizeStringForUrl(provider.providerAddress.city)
    const neighborhood = normalizeStringForUrl(
      provider.providerAddress.neighborhood
    )
    const providerName = normalizeStringForUrl(provider.name)
    const serviceType = provider.providerServiceType?.description
      ? normalizeStringForUrl(provider.providerServiceType.description)
      : ''

    return `/fornecedores/${serviceType}/${state}/${city}/${neighborhood}/${providerName}--${provider.id}`
  }

  const getProviderUrlByProviderId = async (
    providerId: string
  ): Promise<string> => {
    const provider = await ProviderService.findById(providerId)
    return getProviderUrl(provider)
  }

  return {
    getSpaceUrl,
    getSpaceUrlBySpaceId,
    getProviderUrl,
    getProviderUrlByProviderId,
  }
}
